import React from 'react';
import { clsx } from 'clsx';

import styles from './styles.module.css';

export enum ContainerWidth {
  Full = 'full',
  Narrow = 'narrow',
  Default = 'default',
}

type Props = {
  children?: React.ReactNode;
  width?: ContainerWidth;
  showGridWithMaxWidth?: boolean;
  className?: string;
};
export function Container({
  children,
  width,
  showGridWithMaxWidth,
  className,
}: Props) {
  return (
    <div
      className={clsx(
        styles.wrapper,
        {
          [styles['wrapper--narrow']]: width === ContainerWidth.Narrow,
          [styles['wrapper--full']]: width === ContainerWidth.Full,
          [styles['wrapper--gridMaxWidth']]: showGridWithMaxWidth,
        },
        className
      )}
    >
      {children}
    </div>
  );
}
