import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';
import { IconDepopIcon } from '@depop/web-ui-kit/Icons/IconDepopIcon';
import clsx from 'clsx';

import styles from './styles.module.css';

import { Container } from '@/components/Container';
import { ExtendedLink } from '@/components/ExtendedLink';

const supportedStatusCodes = [404, 500] as const;
type SupportedStatusCodes = typeof supportedStatusCodes;

type Props = {
  statusCode?: SupportedStatusCodes[number];
  showDepopLogo?: boolean;
};

export function Error({ statusCode = 404, showDepopLogo = false }: Props) {
  const t = useTranslations('common');

  // next-intl does not support fallback messages so we have to validate the status code ourselves
  const validatedStatusCode = supportedStatusCodes.includes(statusCode)
    ? statusCode
    : 404;

  return (
    <Container>
      {showDepopLogo && (
        <IconDepopIcon
          className={clsx('logo__depop-icon', styles.depopLogo)}
          title="Depop logo"
        />
      )}
      <div className={styles.errorContent}>
        <div>
          <p className={styles.errorMessage}>
            {t.rich(`${validatedStatusCode}.Message`, {
              link: (chunks) => <ExtendedLink href="/">{chunks}</ExtendedLink>,
            })}
          </p>
          <Text>{t(`${validatedStatusCode}.HttpMessage`)}</Text>
        </div>
      </div>
    </Container>
  );
}
